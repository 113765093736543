import React from 'react'
import { Container, Row, Col } from '../../../components/ui/wrapper'
import Timeline from '../../../components/ui/timeline-ru'
import ImageOne from '../../../data/images/bg/machines/coating/selective-big.jpg'
import ImageTwo from '../../../data/images/bg/machines/coating/dipcoating-big.jpg'
import { SectionWrap } from './style'


const TimelineArea = (props) => {
    const content = [
        {
            year: "СЕЛЕКТИВНОЕ",
            image: ImageOne,
            title: "Селективное нанесение покрытий",
            desc: "Линия для нанесения защитных покрытий по конфигурации напоминает стандартную линию поверхностного монтажа, только без устройства нанесения паяльной пасты. Загрузка плат в линии производят устройства загрузки плат. Далее плата поступает  в машину для селективного нанесения плат. Если нанесение с двух сторон плата переворачивается и попадает во вторую машину для нанесения покрытий. В зависимости от типа защитных покрытий плата попадает в печь с ИК полимеризацией или УФ отверждения.",
            path: "/комплексные-решения/линия-покрытия-печатных-плат/селективное-нанесение",
            lang: "Подробно..."
        },
        {
            year: "ПОГРУЖЕНИЕМ",
            image: ImageTwo,
            title: "Нанесение методом погружения",
            desc: "Полное покрытие печатных плат в условиях серийного и крупносерийного производств требует другой организации линии нанесения защитных покрытий. Для таких требований применятся замкнутые линии.  Собранные электронные платы погружаются в емкость с материалом и после этого поступают в камеру полимеризации. Два процесса покрытие плат погружением  и отверждение лака происходят в одной машине.  В зависимости от применяемых материалов могут быть создаваться специальные условия окружающей среды внутри машины (например для силиконовых покрытий)",
            path: "/комплексные-решения/линия-покрытия-печатных-плат/нанесение-погружением",
            lang: "Подробно..."
        }
    ]
    const contentua = [
        {
            year: "СЕЛЕКТИВНЕ",
            image: ImageOne,
            title: "Нанесення методом занурення",
            desc: "Лінія для нанесення захисних покриттів за конфігурацією нагадує стандартну лінію поверхневого монтажу, лише без нанесення паяльної пасти. Завантаження плат у лінію виконують пристрої завантаження плат. Далі плата надходить у машину для селективного нанесення плат. Якщо нанесення з двох сторін плата перевертається та потрапляє у другу машину для нанесення покриттів. Залежно від типу захисних покриттів плата потрапляє в піч з ІЧ полімеризацією або УФ затвердіння.",
            path: "/ua/комплексні-рішення/лінія-покриття-друкованих-плат/селективне-нанесення",
            lang: "Докладно..."
        },
        {
            year: "ЗАНУРЕННЯМ",
            image: ImageTwo,
            title: "Автоматичне встановлення компонентів в отвори",
            desc: "Повне покриття друкованих плат в умовах серійного та багатосерійного виробництв потребує іншої організації лінії нанесення захисних покриттів. Для таких вимог застосовуються замкнуті лінії. Зібрані електронні плати занурюються в ємність із матеріалом і після цього надходять у камеру полімеризації. Два процеси покриття плат зануренням і затвердіння лаку відбуваються в одній машині. Залежно від матеріалів, що застосовуються, можуть бути створені спеціальні умови навколишнього середовища всередині машини (наприклад для силіконових покриттів)",
            path: "/ua/комплексні-рішення/лінія-покриття-друкованих-плат/нанесення-зануренням",
            lang: "Докладно..."
        }
    ]
    if (props.lang === "ru") {
        return (
            <SectionWrap>
                <Container>
                    <Row>
                    <Col lg={12}>
                           <h6 style={{textAlign: "justify"}}>Везде, где требуется надежная работа и длительные периоды эксплуатации, важную роль играет покрытие защитным лаком. Принцип нанесения покрытия зависит от геометрии и требований защиты каждого электронного узла. Не только принцип нанесения покрытия определяет, какую систему покрытия использовать, но и производительность сборки. «Малая» - это производительность примерно до 25 000 сборок в год. «Крупные» - до 3 миллионов сборок в год и более. Когда речь идет о производительности от 25 000 до 50 000 единиц в год, необходимо принять решение о том, покрывать сборки в отдельных машинах или на линиях.</h6>
                        </Col>
                        <Col lg={12}>
                            <Timeline items={content} />
                        </Col>
                    </Row>
                </Container>
            </SectionWrap>
        )
    } else if (props.lang === "uk") {
        return (
            <SectionWrap>
                <Container>
                    <Row>
                    <Col lg={12}>
                           <h6 style={{textAlign: "justify"}}>Скрізь, де потрібна надійна робота та тривалі періоди експлуатації, важливу роль відіграє покриття захисним лаком. Принцип нанесення покриття залежить від геометрії та вимог захисту кожного електронного вузла. Не тільки метод нанесення покриття визначає, яку систему покриття використовувати. Важливе значення має программа випуску. "Мала" програма - це приблизно до 25 000 збірок на рік. "Велика" програма - до 3 мільйонів збірок на рік і більше. Коли йдеться про продуктивність від 25 000 до 50 000 одиниць на рік, необхідно ухвалити рішення про те, чи покривати збірки в окремих машинах або на лініях.</h6>
                        </Col>
                        <Col lg={12}>
                            <Timeline items={contentua} />
                        </Col>
                    </Row>
                </Container>
            </SectionWrap>
        )
    }
}

export default TimelineArea
